<template>
  <vx-card>
    <form @submit.prevent="saveUser()" method="POST">
      <h3 class="text-info">{{ this.$t('Información Básica') }}</h3>

      <div class="vx-row mt-5">
        <!-- nombres -->
        <div class="vx-col w-1/2">
          <label for="name"><b>{{ this.$t('Nombres completos') }}</b></label>
          <vx-input-group>
            <vs-input
              id="name"
              name="name"
              v-model="user.name"
              :danger="!!validate.nameError"
              val-icon-danger="clear"
              autofocus
              required
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.nameError">{{ validate.nameError }}</p>
        </div>

        <!-- nit -->
        <div class="vx-col w-1/2">
          <!-- TODO validar tipo de nit y numero de nit -->
          <label for="nit"><b>{{ this.$t('Número de identificación') }}</b></label>
                
          <div class="flex">
            <vs-input
              id="nit"
              name="nit"
              class="w-full"
              :danger="!!validate.identificationError"
              val-icon-danger="clear"
              v-model="user.identification"
              required
            />
          </div>

          <p class="text-danger text-sm" v-if="!!validate.identificationError">{{ validate.identificationError }}</p>
        </div>
      </div>
      <div class="vx-row mt-5">
      
        <!-- correo electronico -->
        <div class="vx-col w-1/2 mt-5">
          <label for="email"><b>{{ this.$t('Correo Electrónico') }}</b></label>
          <vx-input-group>
            <vs-input
              id="email"
              name="email"
              type="email"
              class="form-control form-group"
              :danger="!!validate.emailError"
              val-icon-danger="clear"
              v-model="user.email"
              placeholder="Ej. carlos.cardona@example.com"
              required
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.emailError">{{ validate.emailError }}</p>
        </div>

        <!-- confirme correo electronico -->
        <div class="vx-col w-1/2 mt-5">
          <label for="emailConfirm"><b>{{ this.$t('Confirme Correo Electrónico') }}</b></label>
          <vx-input-group>
            <vs-input
              id="emailConfirm"
              name="emailConfirm"
              type="email"
              :danger="user.email !== user.email2"
              val-icon-danger="clear"
              v-model="user.email2"
              placeholder="Ej. carlos.cardona@example.com"
              required
            />
          </vx-input-group>
          <p class="text-danger text-sm" v-if="user.email !== user.email2">{{ this.$t('Los correos no coinciden') }}</p>
        </div>

        <!-- contraseña -->
        <div class="vx-col w-2/5 mt-5">
          <label for="password">
            <b>{{ this.$t('Contraseña') }}</b><br/>
            <i class="text-xs">{{ this.$t('Mínimo 6 caracteres 1 Mayúscula y/o Símbolo') }}</i>
          </label>

          <vx-input-group>
            <vs-input
              id="password"
              name="password"
              :type=" isVisiblePassword ? 'text' : 'password' "
              :danger="!!validate.passwordError"
              v-model="user.password"
            />

            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  style="background-color:#b0b0a673 !important;"
                  color="dark"
                  type="flat"
                  icon-pack="feather"
                  :icon=" isVisiblePassword ? 'icon-eye' : 'icon-eye-off'"
                  @click="isVisiblePassword = !isVisiblePassword"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
          <p class="text-danger text-sm" v-if="!!validate.passwordError">{{ validate.passwordError }}</p>
        </div>

        <!-- contraseña 2 -->
        <div class="vx-col w-2/5 mt-5">
          <label for="password2">
            <b>{{ this.$t('Confirmar Contraseña') }}</b><br/><br/>
          </label>

          <vx-input-group>
            <vs-input
              :danger="user.password !== user.password2"
              val-icon-danger="clear"
              id="password2"
              name="password2"
              :type=" isVisiblePassword2 ? 'text' : 'password' "
              v-model="user.password2"
            />

            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button
                  style="background-color:#b0b0a673 !important;"
                  color="dark"
                  type="flat"
                  icon-pack="feather"
                  :icon=" isVisiblePassword2 ? 'icon-eye' : 'icon-eye-off'"
                  @click="isVisiblePassword2 = !isVisiblePassword2"
                ></vs-button>
              </div>
            </template>
          </vx-input-group>
          <p class="text-danger text-sm" v-if="user.password !== user.password2">{{ this.$t('Las contraseñas no coinciden') }}</p>
        </div>

        <!-- generar contraseña -->
        <div class="vx-col w-1/5 mt-16">
          <button type="button" class="vs-component vs-button bg-info vs-button-relief rounded-full float-right" @click="generatePassword()">
            <feather-icon icon="ClipboardIcon" class="align-middle mr-2"></feather-icon>
            {{ this.$t('Generar') }}
            <span class="sm:hidden">{{ this.$t('Contraseña') }}</span>
          </button>
        </div>
      </div>
        
          
      <vs-divider class="my-10"/>

      <!-- Guardar datos boton -->
      <div class="vx-row">
        <div class="w-full mt-10">
          <button
            type="submit"
            class="vs-component vs-button vs-button-primary vs-button-relief w-1/6 rounded-full float-right"
          >
          {{ this.$t('Guardar') }}
          </button>
        </div>
      </div>
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select';

import { Validation } from '../../../plugins/validation.js';

export default {
  data() {
    return {
      validate           : new Validation(),
      isVisiblePassword  : false,
      isVisiblePassword2 : false,
      select_all_stores  : false,
      user: {
        email: '',
        password2: '',
        password: '',
        email2: '',
        name: '',
        identification: ''
      }
    }
  },

  async created() {
    this.clearInputs();
  },

  watch: {
    'user.name'( val ) { this.validate.validateName( val ); },
    'user.identification'(val) { this.validate.validateIdentification( val ); },
    'user.email'(val) { this.validate.validateEmail( val ); },
    'user.password'(val) { this.validate.validatePassword( val ); },
  },

  methods: {
      
    async saveUser() {
      const isValidName           = this.validate.validateName( this.user.name );
      const isValidIdentification = this.validate.validateIdentification( this.user.identification );
      const isValidEmail          = this.validate.validateEmail( this.user.email );
      const isValidEmail2         = this.user.email === this.user.email2;
      const isValidPwd            = this.validate.validatePassword( this.user.password );
      const isValidPwd2           = this.user.password === this.user.password2;
        
      if(
        !isValidName ||
        !isValidIdentification ||
        !isValidEmail ||
        !isValidEmail2 ||
        !isValidPwd ||
        !isValidPwd2
      ) {
        this.$vs.notify({
            title: this.$t('Alerta'),
            text: this.$t('Valide los campos antes de continuar'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
        });
        return;
      }

      this.$vs.loading();

      const payload = {
        name: this.user.name,
        email: this.user.email,
        identification: this.user.identification,
        password: this.user.password
      }
      const res = await this.$store.dispatch( 'clients/userClients', payload );
      //this.$vs.loading.close();
      
      if( res ) {
        this.clearInputs();
        this.$router.push({ path: '/app/clients' });
      } else {
        this.$vs.loading.close();
      }
    },

    generatePassword() {
      let res = '';
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+*<>_-)(/&%$#!';
      const charsLenght = chars.length;

      for(let i = 0; i < 8; i++) {
        res += chars.charAt( Math.floor ( Math.random() * charsLenght) );
      }

      this.user.password = res;
      const isValidPassword = this.validate.validatePassword( res );
        
      if(!isValidPassword) {
        this.user.password = '';
        this.generatePassword();
        return;
      }

      this.user.password = res;
      this.user.password2 = res;
      this.validate.validatePassword2( res, res );
      this.isVisiblePassword2 = true;

      if( !navigator.clipboard ) {
        this.$vs.notify({
          title: this.$t('Error'),
          text: this.$t('No se pudo copiar la contraseña'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
          time: 7000,
        });

      } else {
        navigator.clipboard.writeText(this.user.password);
        this.$vs.notify({
          title: this.$t('Correcto'),
          text: this.$t('Contraseña copiada'),
          iconPack: 'feather',
          icon: 'icon-smile',
          color: 'success',
          time: 7000
        });
      }

      this.isVisiblePassword2 = false;

    },

    clearInputs() {
      this.user.name           = '';
      this.user.identification = '';
      this.user.email          = '';
      this.user.email2         = '';
      this.user.password       = '';
      this.user.password2      = '';
      this.user.id             = 0;
    },

  },

  computed: {
    /* user: {
      get() { // user proxy
        const store = this.$store;
        const user = store.state.clients.client;
        let userProxy = new Proxy( user, {
          set (obj, key, value) {
            store.commit('clients/SET_CLIENTS', {
              ...obj,
              [key]: value,
            });    
            return true
          }
        });
        return userProxy;
      }
        
    }, */
    
  },

  components: { 'v-select': vSelect, }
}
</script>

<style lang="scss" scoped>
  input#pwd{ display: none; }
  .flags{
    padding: 7px;
    border: 1px #dededf solid;
    /* border-radius: 0px; */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .flag_input{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
</style>
